// import { MindsLeftButton } from "@/StyledComponents/HomeStyled";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Link from "next/link";
import { useEffect, useRef } from "react";
// import {
//   StartBuildingMain,
//   StartInnerText
// } from "../../StyledComponents/LandingStyled";
import { MindsLeftButton, StartBuildingMain, StartInnerText } from "@/StyledComponents/HomeStartBuildingStyle";
import Image from "next/image";
import MarqueeLogoCertificate from "../Common/MarqueeLogoCertificate";

gsap.registerPlugin(ScrollTrigger);

export default function StartBuilding() {
  const arrowRef = useRef(null);
  const lightLogosRef = useRef([]);
  const darkLogosRef = useRef([]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      // Arrow Animation (Constant)
      gsap.to(arrowRef.current, {
        x: 10,
        duration: 0.7,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });

      // Logos Animation (Bottom to Top, One by One)
      gsap.fromTo(
        lightLogosRef.current,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          stagger: 0.3,
          scrollTrigger: {
            trigger: lightLogosRef.current[0],
            start: "top 80%",
            toggleActions: "play reverse play reverse",
          },
        }
      );

      gsap.fromTo(
        darkLogosRef.current,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          stagger: 0.3,
          scrollTrigger: {
            trigger: darkLogosRef.current[0],
            start: "top 80%",
            toggleActions: "play reverse play reverse",
          },
        }
      );

      // Refresh ScrollTrigger
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    });

    return () => ctx.revert();
  }, []);
    const logoData = [
    { src: "/svgs/G2LogoSvg.svg", wi: 92, hi: 115, link: "https://www.g2.com/products/it-services-india/reviews" },
    { src: "/svgs/GoodFirmsSvg.svg", wi: 148, hi: 107, link: "https://www.goodfirms.co/company/it-services-india" },
    { src: "/svgs/SoftwareWorldLogoSvg.svg", wi: 64, hi: 106, link: "https://www.softwareworld.co/service/it-services-india-reviews/" },
    { src: "/svgs/ISO90012015Svg.svg", wi: 95, hi: 116, link: "https://www.iafcertsearch.org/certified-entity/75veFNFwwS7owds8gH8C1RUV" },
    { src: "/svgs/ISO270032013Svg.svg", wi: 95, hi: 116, link: "https://www.iafcertsearch.org/certified-entity/75veFNFwwS7owds8gH8C1RUV" },
    { src: "/svgs/CMMIDEV3Svg.svg", wi: 149, hi: 97, link: "/" },
  ];

  const logoDataDark = [
    { src: "/svgs/G2LogoSvg-Dark.svg", wi: 92, hi: 115, link: "https://www.g2.com/products/it-services-india/reviews" },
    { src: "/svgs/GoodFirmsSvg-Dark.svg", wi: 148, hi: 107, link: "https://www.goodfirms.co/company/it-services-india" },
    { src: "/svgs/SoftwareWorldLogoSvg-Dark.svg", wi: 64, hi: 106, link: "https://www.softwareworld.co/service/it-services-india-reviews/" },
    { src: "/svgs/ISO90012015Svg-Dark.svg", wi: 95, hi: 116, link: "https://www.iafcertsearch.org/certified-entity/75veFNFwwS7owds8gH8C1RUV" },
    { src: "/svgs/ISO270032013Svg-Dark.svg", wi: 95, hi: 116, link: "https://www.iafcertsearch.org/certified-entity/75veFNFwwS7owds8gH8C1RUV" },
    { src: "/svgs/CMMIDEV3Svg-Dark.svg", wi: 149, hi: 97, link: "/" },
  ];
  return (
    <StartBuildingMain className="container p-b-section startbuild">
      <div className="hidden md:block lg:block">
        <div className="grid gap-20 justify-between grid-cols-5 justify-items-center items-center lg:flex lg:items-center lg:justify-between capitalize ">
        {logoData.map((logo, index) => (
            <Link key={index} href={logo.link} target="_blank" ref={(el) => (lightLogosRef.current[index] = el)} aria-label={`Logo ${index + 1}`}>
              <Image src={logo.src} width={logo.wi} height={logo.hi} alt={`Logo ${index + 1}`} loading="lazy" className="light-logo" />
            </Link>
          ))}
        </div>

        <div className="grid gap-20 justify-between grid-cols-5 justify-items-center items-center lg:flex lg:items-center lg:justify-between capitalize ">
        {logoDataDark.map((logo, index) => (
            <Link key={index} href={logo.link} target="_blank" ref={(el) => (darkLogosRef.current[index] = el)} aria-label={`Logo ${index + 1}`}>
              <Image src={logo.src} width={logo.wi} height={logo.hi} alt={`Logo ${index + 1}`} loading="lazy" className="dark-logo" />
            </Link>
          ))}
        </div>
      </div>
      <div className="scrolllogo-first block md:hidden lg:hidden">
        <MarqueeLogoCertificate />
      </div>
      <StartInnerText>
        Let’s Transform your Ideas into <br className="hidden lg:block" />
        Tech Success!
      </StartInnerText>

      <MindsLeftButton>
        <a
          href="/lets-talk"
          target="_self"
          className="h-btn btn dark-btn gap-[10px] common-btn-hover !mt-[30px]"
        >
          Get estimation{" "}
          <span ref={arrowRef}>           
            <Image
              src="/svgs/Right-Arrow-White.svg"
              alt="Right-Arrow-White"
              width={16}
              height={16}
              loading="lazy"
              className="w-full "
            />
          </span>
        </a>
      </MindsLeftButton>
    </StartBuildingMain>
  );
}
